import WhatsappIcon from "@material-ui/icons/WhatsApp";

const contacts = [
    {
        title: 'Whatsapp',
        icon: <WhatsappIcon fontSize="large"/>,
        phone: '+56 9 9991 5077',
    },
];

export default contacts